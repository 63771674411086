<template>
  <v-card>
    <v-card-title>前月繰越</v-card-title>

    <v-row
      class="px-2 mt-2"
      align="center"
    >
      <v-col
        cols="12"
        md="3"
        class="py-4"
      >
        <v-autocomplete
          v-model="item_code"
          :items="itemCodes"
          placeholder="単品コード"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1">
        <v-icon class="me-1" @click="reduceMonth">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-col>
      <v-col cols="2">
        <label>{{ selectedDate.substring(0, 4) }}年{{ selectedDate.substring(4,6) }}月</label>
      </v-col>
      <v-col v-if="selectedDate < lastMonth" cols="1">
        <v-icon class="me-1" @click="addMonth">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-col>
      <v-col v-else cols="1">
      </v-col>
    </v-row>

    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        ref="MthlyProdStockSt"
        :headers="headers"
        :items="f_MonthlyCarryovers"
        item-key="item_code"
        :items-per-page="-1"
      >
        <template v-slot:[`item.monco_qty`]="{ item }">
          <label :style="item.monco_qty < 0 ? 'color:#F44336' : ''">{{ item.monco_qty ? item.monco_qty.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col cols="12">
              <v-icon
                class="mr-2"
                small
                @click.stop="openEditForm(item.id)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
              <carryover-edit
                ref="carryoverEdit"
                @closeForm="reload"
              ></carryover-edit>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

import CarryoverEdit from './CarryoverEdit.vue'

export default {
  components: {
    CarryoverEdit,
  },
  data: () => ({
    icons: {
      mdiPencil, mdiChevronLeft, mdiChevronRight,
    },
    item_code: '',
    exportStatus: false,

    lastMonth: new Date(new Date(Date.now()).setDate(0) - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 7).replace('-', ''),
    headers: [
      {
        text: '単品コード',
        align: 'left',
        value: 'item_code',
        width: '20%',
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        value: 'item_name',
        width: '50%',
        fixed: true,
      },
      {
        text: '在庫数量',
        value: 'monco_qty',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('monthlyCarryoverStore', ['MonthlyCarryover', 'f_MonthlyCarryovers']),
    ...mapState('itemStore', ['itemCodes']),

    selectedDate() {
      console.log('route:', this.$route.params)

      return this.$route.params.month
    },
  },

  watch: {
    item_code(value) {
      this.filterMonthlyCarryover(value)
    },
  },

  mounted() {
    // this.$emit('has-mounted')
    const selMonth = this.$route.params.month
    this.loadData(selMonth)
  },

  methods: {
    ...mapActions('monthlyCarryoverStore', ['loadMonthlyCarryovers']),
    ...mapActions('itemStore', ['loadItemCodes']),
    ...mapMutations('monthlyCarryoverStore', ['filterMonthlyCarryover']),
    ...mapMutations('app', ['setOverlayStatus']),
    openEditForm(id) {
      this.$refs.carryoverEdit.openForm(id)
    },
    reload() {
      const selMonth = this.$route.params.month
      this.loadData(selMonth)
    },

    loadData(selMonth) {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadItemCodes(),
        this.loadMonthlyCarryovers(selMonth),
      ]).then(() => {
        this.filterMonthlyCarryover(this.item_code)

        // this.itemCodes = this.MonthlyCarryovermap(ca => ca.imte_code)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    reduceMonth() {
      const date = new Date(`${this.selectedDate.substring(0, 4)}-${this.selectedDate.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substring(0, 7).replace('-', '')
      this.$router.push({
        name: 'stock-prevmon',
        params: {
          month: selMonth,
        },

      })

      this.loadData(selMonth)
    },
    addMonth() {
      // const date = new Date(`${this.selecteDate.substring(0, 4)}-${this.selectedDate.substring(4, 6)}`)
      // console.log('date:', date)
      // const selMonth = new Date(date.setMonth(date.getMonth() + 1)).toISOString().substring(0, 7).replace('-', '')
      const date = new Date(`${this.selectedDate.substring(0, 4)}-${this.selectedDate.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() + 1)).toISOString().substring(0, 7).replace('-', '')
      console.log(selMonth, this.lastMonth)

      if (selMonth <= this.lastMonth) {
        this.$router.push({
          name: 'stock-prevmon',
          params: {
            month: selMonth,
          },

        })
      }

      this.loadData(selMonth)
    },
  },
}
</script>
