<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span
          class="text-h5"
          style="color: red"
        >月末繰越</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="5">
              <label class="text-h6">対象年月</label>
            </v-col>
            <v-col cols="7">
              <label
                class="text-h6"
              >{{ item.yyyymm }}</label>
            </v-col>
            <v-col cols="5">
              <label class="text-h6">単品コード</label>
            </v-col>
            <v-col cols="7">
              <label
                class="text-h6"
              >{{ item.item_code }}</label>
            </v-col>
            <v-col cols="12">
              <number-input
                v-model="item.monco_qty"
                label="在庫数量*"
                :outlined="false"
                :error-messages="AmountErrors"
              >
              </number-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="submitStatus"
          @click="submit(item)"
        >
          YES
        </v-btn>
        <v-btn
          color="error"
          @click="closeForm()"
        >
          NO
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  required, decimal,
} from 'vuelidate/lib/validators'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    NumberInput,
  },
  data: () => ({
    submitStatus: false,
    dialog: false,
    item: {
      item_code: '',
      monco_qty: '',
    },
  }),
  validations: {
    item: {
      item_code: {
        required,
      },
      monco_qty: {
        required,
        decimal,
      },
    },
  },
  computed: {
    ...mapState('monthlyCarryoverStore', ['selMonthlyCarryover']),
    AmountErrors() {
      const errors = []
      if (!this.$v.item.monco_qty.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.monco_qty.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.monco_qty.decimal && errors.push('必須数字')

      return errors
    },
  },
  methods: {
    ...mapActions('monthlyCarryoverStore', ['loadMonthlyCarryover', 'editMonthlyCarryover']),
    ...mapMutations('monthlyCarryoverStore', ['clearSelectedMonthlyCarryover']),
    ...mapMutations('app', ['setOverlayStatus']),
    submit(item) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editMonthlyCarryover(item)
          .then(() => {
            this.closeForm()
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!', this.item)
      }
    },

    openForm(id) {
      this.setOverlayStatus(true)
      this.loadMonthlyCarryover(id)
        .then(() => {
          this.item = this.$deepCopy(this.selMonthlyCarryover)
          console.log('loadMonthlyCarryover', this.selMonthlyCarryover)
        })
        .finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },
    closeForm() {
      this.dialog = false
      this.$emit('closeForm')
      this.clearSelectedMonthlyCarryover()
      this.item = {
      }
    },
  },
}
</script>
